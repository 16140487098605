"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrollPrev = scrollPrev;
exports.scrollNext = scrollNext;
var $ = require("jquery");
function scrollPrev() {
    var container = $(".arf-featured__list");
    var width = container.children().first().width();
    container.css("scroll-snap-type", "none");
    container.animate({ scrollLeft: container.scrollLeft() - (width + 25) }, 250, function () { return container.css("scroll-snap-type", "x mandatory"); });
}
function scrollNext() {
    var container = $(".arf-featured__list");
    var width = container.children().first().width();
    container.css("scroll-snap-type", "none");
    container.animate({ scrollLeft: container.scrollLeft() + (width + 25) }, 250, function () { return container.css("scroll-snap-type", "x mandatory"); });
}
