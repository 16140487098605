"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerHeaderEvents = registerHeaderEvents;
exports.toggle_submenu = toggle_submenu;
exports.toggle_vessels_browser = toggle_vessels_browser;
exports.registerVesselsLinks = registerVesselsLinks;
exports.registerLabsLinks = registerLabsLinks;
exports.vessels_browser_filter = vessels_browser_filter;
exports.scrollDown = scrollDown;
var $ = require("jquery");
function setMultilineText(el, text) {
    el.text(text);
    el.html(el.html().replace(/\n/g, '<br/>'));
    return el;
}
function registerHeaderEvents() {
    $(document).on('scroll', function () {
        var header = $('.arf-header');
        if ($(this).scrollTop() > 0) {
            header.addClass('solid');
        }
        else {
            header.removeClass('solid');
        }
    });
    $(document).on('click', 'a.arf-mobile-menu__link', function () {
        toggle_submenu();
    });
    $(document).on('click', '.arf-vessels-browser-vessel-link, .arf-vessels-browser__preview-panel__rent-button, .arf-vessels-browser-lab-link', function () {
        toggle_vessels_browser();
        toggle_submenu();
    });
}
function toggle_submenu() {
    $('.arf-header').toggleClass("isSubmenuOpen").removeClass("isVesselsBrowserOpen");
    $('.arf-header__menu-bar').toggleClass("isSubmenuOpen");
    $('.arf-header__menu-bar__drawer').toggleClass("isOpen");
    $('.arf-header__submenu-button').toggleClass("isOpen");
    $('.arf-mobile-menu').toggleClass('isOpen');
    $('.arf-header__vessels-browser-button').removeClass("isActive");
    $('.arf-vessels-browser').removeClass("isBrowserOpen");
}
function toggle_vessels_browser() {
    // Toggle the browser
    $('.arf-header').toggleClass("isVesselsBrowserOpen");
    $('.arf-header__vessels-browser-button').toggleClass("isActive");
    $('.arf-vessels-browser').toggleClass("isBrowserOpen");
}
function registerVesselsLinks(vessels) {
    $(document).on("mouseenter", '.arf-vessels-browser-vessel-link', function () {
        var vesselId = this.dataset.vesselId;
        var vessel = vessels.find(function (vessel) { return vessel.id === vesselId; });
        $('.arf-vessels-browser__preview-panel')
            .addClass("isVisible")
            .css('background-image', "url(".concat(vessel.picture_url, ")"));
        $('.arf-vessels-browser__preview-panel__title').text(vessel.full_name);
        $('.arf-vessels-browser__preview-panel .short-name').text(vessel.full_name.replace('R/V ', ''));
        setMultilineText($('.arf-vessels-browser__preview-panel .preview-first-stat-label'), vessel.first_stat_label);
        $('.arf-vessels-browser__preview-panel .preview-first-stat-value').text(vessel.first_stat_value).parent().find('*').toggle(!!vessel.first_stat_value);
        $('.arf-vessels-browser__preview-panel .preview-first-stat-unit').text(vessel.first_stat_unit);
        setMultilineText($('.arf-vessels-browser__preview-panel .preview-second-stat-label'), vessel.second_stat_label);
        $('.arf-vessels-browser__preview-panel .preview-second-stat-value').text(vessel.second_stat_value).parent().find('*').toggle(!!vessel.second_stat_value);
        $('.arf-vessels-browser__preview-panel .preview-second-stat-unit').text(vessel.second_stat_unit);
        $('.arf-vessels-browser__preview-panel__map-link').attr('href', vessel.map_link).toggleClass('visibility_hidden', !vessel.map_link);
    });
}
function registerLabsLinks(labs) {
    $(document).on("mouseenter", '.arf-vessels-browser-lab-link', function () {
        var labId = this.dataset.labId;
        var lab = labs.find(function (lab) { return lab.id === labId; });
        $('.arf-vessels-browser__preview-panel')
            .addClass("isVisible")
            .css('background-image', "url(".concat(lab.picture_url, ")"));
        $('.arf-vessels-browser__preview-panel__title').text(lab.full_name);
        $('.arf-vessels-browser__preview-panel .short-name').text(lab.full_name);
        setMultilineText($('.arf-vessels-browser__preview-panel .preview-first-stat-label'), lab.first_stat_label);
        $('.arf-vessels-browser__preview-panel .preview-first-stat-value').text(lab.first_stat_value);
        $('.arf-vessels-browser__preview-panel .preview-first-stat-unit').text(lab.first_stat_unit);
        setMultilineText($('.arf-vessels-browser__preview-panel .preview-second-stat-label'), lab.second_stat_label);
        $('.arf-vessels-browser__preview-panel .preview-second-stat-value').text(lab.second_stat_value);
        $('.arf-vessels-browser__preview-panel .preview-second-stat-unit').text(lab.second_stat_unit);
        $('.arf-vessels-browser__preview-panel__map-link').addClass('visibility_hidden');
        // $('.arf-vessels-browser__preview-panel__rent-button').addClass('visibility_hidden');
    });
}
function vessels_browser_filter(show) {
    $('.arf-vessels-browser__filter-all').toggleClass('isActive', show === 'all');
    $('.arf-vessels-browser__filter-vessels').toggleClass('isActive', show === 'vessels');
    $('.arf-vessels-browser__filter-labs').toggleClass('isActive', show === 'labs');
    $('.arf-vessels-browser__left-panel__separator').toggle(show === 'all');
    $('.arf-vessels-browser__vessels-section').toggle(show === 'vessels' || show === 'all');
    $('.arf-vessels-browser__labs-section').toggle(show === 'labs' || show === 'all');
}
function scrollDown() {
    var _a;
    var next_el = $('.arf-jumbo-cover__top').next().first();
    if (!next_el.length) {
        next_el = $('.arf-default-grid');
    }
    var scroll = (_a = next_el === null || next_el === void 0 ? void 0 : next_el.offset().top) !== null && _a !== void 0 ? _a : 0;
    $(document.documentElement).animate({ scrollTop: scroll - 150 }, 250);
}
$(document).on('click', '.arf-header__menu-bar__drawer a', function () {
    toggle_submenu();
});
